import { send } from "../index";
// 获取文献列表
export function getDocuList(data, opts = {}) {
  return send({
    url: "/admin/document/documentList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 修改文献
export function updateDocument(data, opts = {}) {
  return send({
    url: "/admin/document/update.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 删除文献
export function deleteDocument(data, opts = {}) {
  return send({
    url: "/admin/document/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 新增文献
export function saveDocument(data, opts = {}) {
  return send({
    url: "/admin/document/save.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 文献分类
export function getDocumentTypeList(data, opts = {}) {
  return send({
    url: "/admin/document/documentTypeList.do",
    method: "POST",
    data,
    ...opts,
  });
}