<template>
	<div>
		<a-spin :spinning="loading">
		<a-row>
			<a-col :span="8" :xl="6" :xxl="5" :xxxl="4">
				<a-input-search v-model:value="fileName" enter-button placeholder="请输入搜索的文件名或标题" @search="onSearch"></a-input-search>
				<div class="ui-searchMenu">
					<a-menu v-model:selectedKeys="typeId">
						<a-menu-item v-for="(item,index) in category" :key="item.id" @click="onSearchType(item.id)">{{item.name}}</a-menu-item>
					</a-menu>
				</div>
			</a-col>
			<a-col :span="16" :xl="18" :xxl="19" :xxxl="20" style="padding-left: 40px;">
				<div class="ui-book">
					<div class="ui-book-box" ref="bookBoxRef">
						<div v-if="list.length">
							<a-carousel :dots="false" arrows :afterChange="afterChange" :beforeChange="beforeChange">
								<template #prevArrow v-if="current !== 0">
									<div class="custom-slick-arrow" style="left: 10px; z-index: 1">
										<Icon icon="LeftCircleOutlined" />
									</div>
								</template>
								<template #nextArrow v-if="current !== list.length - 1">
									<div class="custom-slick-arrow" style="right: 10px">
										<Icon icon="RightCircleOutlined" />
									</div>
								</template>
								<div v-for="(item, index) in list" :key="item.id">
									<div class="ui-book__list">
										<div v-for="subItem in item" :key='subItem.id' :class="['ui-book__item', lookId === subItem.id && 'is-action']" @click.stop="onLook(subItem)">
											<div class="ui-book__title">
												<!-- 1.行政单位国有资产管理暂行办法（财政部令 第35号） -->
												{{subItem.fileName}}
											</div>
											<div class="ui-book__time"> {{ transDateTime(subItem.releaseTime, 1) }} </div>
										</div>
									</div>
								</div>
							</a-carousel>
						</div>
					</div>
					<div class="ui-info" v-if="list.length">
						<div class="ui-info__title" @click="onSeeFile">
							{{this.currentDetail.fileName}}
						</div>
						<div class="ui-info__row">
							<span>发行时间：</span>{{transDateTime(this.currentDetail.releaseTime,1)}}
						</div>
						<div class="ui-info__row">
							<span>发文单位：</span>
							{{this.currentDetail.unitId}}
						</div>
						<div class="ui-info__row">
							<span>文号：</span>
							{{this.currentDetail.fileNum}}
						</div>
						<div class="ui-info__row">
							<span>内容摘要：</span>
							<div class="ui-info__content">
								{{this.currentDetail.remark}}
							</div>
						</div>
					</div>
					<div v-if="list.length === 0">
						<h2 style="text-align: center;margin-top: 50px;">暂无相关文献</h2>
					</div>
				</div>
			</a-col>
		</a-row>
		</a-spin>
	</div>
</template>

<script>
	import { getDocumentTypeList, getDocuList } from '@/service/modules/document.js';
	import { Icon } from '@/components/icon/icon.js';
	export default {
		components: { Icon },
		data() {
			return {
				loading: false,
				fileName: undefined,
				lookId: -1,
				current: 0,
				page: 1,
				hasNextPage: true,
				typeId: [],
				// cancelSel: true,
				category: {},
				currentDetail: {},
				itemNum: 0,	// 每一页的最大显示数量
				list: []
			}
		},
		mounted() {
			let boxWidth = this.$refs.bookBoxRef.offsetWidth;
			this.itemNum = Math.floor(boxWidth / 170);
			this.getData()
			this.getDataType()
		},
		methods: {
			async getDataType() {
				this.loading = true;
				try {
					let ret = await getDocumentTypeList();
					this.loading = false;
					if (ret.code === 200) {
						this.category = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getDocuList({
						page: this.page,
						pageSize: this.itemNum * 3,
						fileName: this.fileName ? this.fileName : undefined,
						fileTypeId: this.typeId.length ? this.typeId[0] : undefined
					});
					this.loading = false;
					if (ret.code === 200) {
						let data = ret.data.list;
						this.hasNextPage = ret.data.hasNextPage;
						if (data.length) {
							this.lookId = data[0].id;
							this.currentDetail = data[0];
							if (this.page === 1) {
								this.list = [];
							}
							let list = [];
							let i = 0;
							if (data.length > this.itemNum) {
								data.forEach((item,index)=>{
									if (this.itemNum === i) {
										this.list.push(list);
										list = [];
										i= 0;
									} else {
										list.push(item)
										i++ ;
									}
								});
							} else {
								this.list.push(data)
							}
						} else if (this.page === 1) {
							this.current = 0;
							this.list = [];
							this.currentDetail = {};
						}
						this.$nextTick(()=> {
							this.$forceUpdate();
						})
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSearch(value) {
				this.typeId = [];
				this.page = 1;
				this.current = 0;
				this.getData();
			},
			// onSearchFromId() {
				
			// }
			onSearchType(id) {
				this.typeId = [id];
				this.page = 1;
				this.current = 0;
				this.getData();
			},
			onLook(item) {
				this.lookId = item.id;
				this.currentDetail = item;
			},
			onSeeFile() {
				if (this.currentDetail.fileUrl) {
					window.open(this.currentDetail.fileUrl);
				}
			},
			afterChange(e) {
				if (e !== undefined) {
					this.current = e;
					if (this.current === this.list.length - 1 && this.hasNextPage) {
						this.page += 1;
						this.getData();
					}
				}
			},
			beforeChange(e) {
				if (e !== undefined) {
					this.current = e;
				}
			}
		}
	}
</script>

<style>
	.ui-searchMenu .ant-menu-vertical {
		border: none;
	}

	.ui-searchMenu .ant-menu-item:not(:last-child) {
		margin: 0;
	}
</style>
<style scoped>
	.ui-searchMenu {
		padding: 10px 0;
	}

	.ui-book {
		height: 100%;
		padding-left: 40px;
		border-left: solid 1px #eee;
	}
	.ui-book-box {
		border-radius: 8px;
		box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.06);
	}
	.ui-book__list {
		display: flex;
		padding: 20px 50px;
	}
	.ui-book__item {
		display: flex;
		width: 150px;
		height: 200px;
		padding: 20px 10px;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 10px;
		background-color: #dfdee1;
		color: #303A5D;
		box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.06);
		cursor: pointer;
	}
	.ui-book__item + .ui-book__item {
		margin-left: 30px;
	}
	.is-action {
		color: #FFFFFF;
		background-color: #3E74EF;
	}
	.ui-book__title {
		font-size: 15px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		line-height: 22px;
		text-align: center;
		height: 135px;
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-line-clamp:6;
		text-overflow: ellipsis;
		overflow:hidden;
	}
	.ui-book__time {
		font-size: 14px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		line-height: 16px;
		text-align: center;
	}
	.ui-info {
		padding: 40px 10px 30px;
	}
	.ui-info__title {
		margin-bottom: 20px;
		font-size: 18px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		line-height: 22px;
		color: #3E74EF;
	}
	.ui-info__row {
		font-size: 15px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		line-height: 22px;
	}
	.ui-info__row span {
		color: #666666;
	}
	.ui-info__row + .ui-info__row {
		margin-top: 8px;
	}
	.ui-info__content {
		padding: 6px 0 0 20px;
	}
	
	.ant-carousel :deep(.slick-slide) {
	  text-align: center;
	  height: 240px;
	  /* line-height: 160px; */
	  overflow: hidden;
	}
	
	.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
	  width: 25px;
	  height: 25px;
	  font-size: 25px;
	  color: rgba(31, 45, 61, 0.81);
	  /* background-color: rgba(31, 45, 61, 0.41); */
	  opacity: 0.3;
	  z-index: 1;
	}
	.ant-carousel :deep(.custom-slick-arrow:before) {
	  display: none;
	}
	.ant-carousel :deep(.custom-slick-arrow:hover) {
	  opacity: 0.5;
	}
	
	.ant-carousel :deep(.slick-slide h3) {
	  color: #fff;
	}
</style>
